// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-antiadj-js": () => import("./../src/pages/antiadj.js" /* webpackChunkName: "component---src-pages-antiadj-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fairychess-js": () => import("./../src/pages/fairychess.js" /* webpackChunkName: "component---src-pages-fairychess-js" */),
  "component---src-pages-iclibrary-js": () => import("./../src/pages/iclibrary.js" /* webpackChunkName: "component---src-pages-iclibrary-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intimpossible-js": () => import("./../src/pages/intimpossible.js" /* webpackChunkName: "component---src-pages-intimpossible-js" */),
  "component---src-pages-pokecard-js": () => import("./../src/pages/pokecard.js" /* webpackChunkName: "component---src-pages-pokecard-js" */),
  "component---src-pages-reviews-js": () => import("./../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-thanks-js": () => import("./../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

